<template>
  <div v-if="productReview">
    <v-alert
      border="left"
      color="indigo"
      class="mr-12"
      dark
    >
      <b>{{ productReview.user.profile.first_name }}, {{ productReview.user.profile.last_name }}</b>: {{ productReview.content }}
    </v-alert>

    <v-alert
      v-for="comment in productReview.comments"
      :key="comment.uuid"
      border="right"
      class="ml-12 text-right"
    >
      {{ comment.content }} - <b>{{ comment.user.full_name }}</b>

      <v-icon @click="onDelete(comment)">
        {{ icons.mdiDelete }}
      </v-icon>
    </v-alert>

    <v-alert
      v-if="canCreateComment"
      border="right"
      class="ml-12 text-right"
    >
      <v-text-field
        v-model="form.content"
        placeholder="Leave a comment"
      >
      </v-text-field>

      <v-btn
        v-show="form.content"
        color="success"
        :loading="loading"
        @click="onSubmit"
      >
        Save
      </v-btn>
    </v-alert>
  </div>
</template>

<script>
import { useConfirm } from '@/composables'
import { mdiDelete } from '@mdi/js'
import { computed, ref, watch } from '@vue/composition-api'
import { productReviewCommentService } from '../../services'

export default {
  props: {
    productReview: {
      type: Object,
      default: () => null,
    },
    canCreateComment: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const loading = ref(false)
    const form = ref({
      content: '',
    })

    const onSubmit = () => {
      loading.value = true

      productReviewCommentService.store({
        ...form.value,
        review_id: props.productReview.id,
      }).then(comment => {
        props.productReview.comments.push({
          ...comment,
          user: {
            full_name: 'Admin',
          },
        })

        form.value.content = ''
      }).finally(() => {
        loading.value = false
      })
    }

    const onDelete = async item => {
      await useConfirm({
        title: 'Delete comment',
        message: 'Are you sure you want to delete this comment?',
      })
      console.log('item', item)
      loading.value = true
      await productReviewCommentService.destroy(item.id).then(() => {
        props.productReview.comments = props.productReview.comments.filter(comment => comment.id !== item.id)
      })
    }

    const detemineIsBusy = () => {
      if (form.value.content) {
        console.log('is busy')
        emit('is-busy', true)
      } else {
        console.log('not busy')
        emit('is-busy', false)
      }
    }

    watch(computed(() => form.value.content), () => {
      console.log('change')
      detemineIsBusy()
    })

    return {
      loading,
      form,

      onSubmit,
      onDelete,
      detemineIsBusy,

      icons: {
        mdiDelete,
      },
    }
  },
}
</script>
