var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-data-table',{staticClass:"table-section text-no-wrap",attrs:{"headers":_vm.columns,"items":_vm.items,"options":_vm.options,"server-items-length":_vm.itemsTotal,"loading":_vm.loading,"header-props":_vm.headerprops},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.id)+" ")]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.formatDate(item.created_at)))])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.status))])]}},{key:"item.summary",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"width":"450px","text-wrap":"balance"}},[_vm._v(" "+_vm._s(item.summary)+" ")])]}},{key:"item.approvable_type",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.getApprovableType(item)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""},on:{"click":function($event){_vm.resourceId = item.approvable_id}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)]}}],null,true)},[(_vm.resourceId)?_c('v-list',[(_vm.getApprovableType(item) === 'ProductReview')?_c('approval-dialog',{attrs:{"resource-id":_vm.resourceId},on:{"updated":_vm.paginate}}):_vm._e()],1):_vm._e()],1)],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }