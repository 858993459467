<template>
  <v-card>
    <v-data-table
      :headers="columns"
      :items="items"
      :options.sync="options"
      :server-items-length="itemsTotal"
      :loading="loading"
      :header-props="headerprops"
      class="table-section text-no-wrap"
    >
      <!-- ID -->
      <template #[`item.id`]="{ item }">
        {{ item.id }}
      </template>

      <!-- date -->
      <template #[`item.created_at`]="{item}">
        <span class="text-no-wrap">{{ formatDate(item.created_at) }}</span>
      </template>

      <!-- status -->
      <template #[`item.status`]="{item}">
        <span class="text-no-wrap">{{ item.status }}</span>
      </template>

      <!-- summary -->
      <template #[`item.summary`]="{item}">
        <div style="width: 450px; text-wrap: balance">
          {{ item.summary }}
        </div>
      </template>

      <!-- approvable_type -->
      <template #[`item.approvable_type`]="{item}">
        <span>{{ getApprovableType(item) }}</span>
      </template>

      <!-- actions -->
      <template #[`item.actions`]="{ item }">
        <div class="d-flex align-center justify-center">
          <v-menu
            bottom
            left
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small
                icon
                v-bind="attrs"
                v-on="on"
                @click="resourceId = item.approvable_id"
              >
                <v-icon size="18">
                  {{ icons.mdiDotsVertical }}
                </v-icon>
              </v-btn>
            </template>

            <v-list v-if="resourceId">
              <approval-dialog
                v-if="getApprovableType(item) === 'ProductReview'"
                :resource-id="resourceId"
                @updated="paginate"
              />
            </v-list>
          </v-menu>
        </div>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import ApprovalDialog from '@/modules/product/components/productReview/ApprovalDialog.vue'
import { t } from '@/plugins/i18n'
import { formatDate } from '@core/utils/filter'
import { mdiDotsVertical } from '@mdi/js'
import { ref } from '@vue/composition-api'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

export default {
  components: {
    ApprovalDialog,
  },
  props: {
    tableList: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const {
      loading, options, paginate, items, itemsTotal, headerprops,
    } = props.tableList

    const resourceId = ref(0)

    const getApprovableType = item => item.approvable_type.replace(/\\/g, '').replace(/Modules/g, '').replace(/Models/g, '')

    const columns = [
      { text: 'ID', value: 'id' },
      { text: 'DATE', value: 'created_at' },
      { text: 'STATUS', value: 'status' },
      { text: 'SUMMARY', value: 'summary' },
      { text: 'TYPE', value: 'approvable_type' },
      { text: 'Actions', value: 'actions', sortable: false },
    ]

    return {
      resourceId,
      getApprovableType,

      t,
      dayjs,
      formatDate,

      // Table config
      loading,
      paginate,
      options,
      items,
      itemsTotal,
      headerprops,

      columns,

      icons: {
        mdiDotsVertical,
      },
    }
  },
}
</script>
