<template>
  <v-dialog
    v-model="isDialogVisible"
    max-width="800"
    full-height
  >
    <!-- Dialog Activator -->
    <template #activator="{ on, attrs }">
      <v-list-item
        link
        v-bind="attrs"
        v-on="on"
      >
        <v-list-item-title>
          <v-icon
            size="17"
            class="me-3"
          >
            {{ icons.mdiCheckCircleOutline }}
          </v-icon>
          <span>Approval...</span>
        </v-list-item-title>
      </v-list-item>
    </template>

    <!-- Dialog Content -->
    <v-card>
      <v-card-title>Review Approval</v-card-title>

      <v-card-text>
        <ProductReviewDetails
          v-if="loadedResource"
          :product-review="loadedResource"
          :can-create-comment="true"
          @is-busy="bol => isBusy = bol"
        >
        </ProductReviewDetails>
      </v-card-text>

      <v-card-actions>
        <v-btn
          color="error"
          class="mr-4"
          :loading="loading"
          :disabled="isBusy"
          @click="onReject"
        >
          Reject
        </v-btn>

        <v-btn
          class="mr-4"
          color="success"
          :loading="loading"
          :disabled="isBusy"
          @click="onApprove"
        >
          Approve
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { useNotifyErrors, useNotifySuccess } from '@/composables'
import { mdiCheckCircleOutline, mdiRefresh } from '@mdi/js'
import { onMounted, ref } from '@vue/composition-api'
import { productReviewService } from '../../services'
import ProductReviewDetails from './ProductReviewDetails.vue'

export default {
  components: {
    ProductReviewDetails,
  },
  props: {
    resource: { // either one is required
      type: Object,
      default: () => null,
    },
    resourceId: { // either one is required
      type: Number,
      default: 0,
    },
  },
  setup({ resource, resourceId }, { emit }) {
    if (!resource && !resourceId) {
      throw new Error('props.resource or props.resourceId is required')
    }
    const formRef = ref(null)
    const loading = ref(false)
    const isDialogVisible = ref(false)
    const isBusy = ref(false)

    const loadedResource = ref(resource || null)

    onMounted(async () => {
      if (resourceId) {
        loadedResource.value = await productReviewService.show(resourceId)
      }
    })

    const performApproval = async action => {
      loading.value = true

      await productReviewService[action](loadedResource.value.id).then(() => {
        isDialogVisible.value = false

        emit('updated')

        useNotifySuccess({
          content: `product review has been ${action}ed.`,
        })
      }).catch(error => {
        useNotifyErrors(error)
      }).finally(() => {
        loading.value = false
      })
    }

    const onReject = () => {
      performApproval('reject')
    }

    const onApprove = async () => {
      performApproval('approve')
    }

    return {
      loadedResource,

      formRef,
      isDialogVisible,
      isBusy,

      onReject,
      onApprove,

      loading,

      icons: {
        mdiCheckCircleOutline,
        mdiRefresh,
      },
    }
  },
}
</script>
